.download-btn{
    width: 195px;
    height:67px;
    line-height:67px;
    margin-top:17px;
    background-color:#303E68;
    color: white;
    text-align: center;
    border-radius: 10px;
    font-size: 24px;
    font-weight:bold;
}
.detail-btn{
    width: 195px;
    height:67px;
    line-height:67px;
    margin-top:17px;
    background-color:#7b81ef;
    color: white;
    text-align: center;
    border-radius: 10px;
    font-size: 24px;
    font-weight:bold;
}
.product {
    margin-top: 90px;

    .box {
        padding-top: 66px;

        .content {
            margin-right: 112px;
            width: 400px;
        }
        .content-right{
            margin-left 112px
            width: 400px
        }
    }
    .box:nth-child(2){
        background: #FAFAFA
    }
    .box:nth-child(4){
        background: #FAFAFA
    }
    .box:nth-child(6){
        background: #FAFAFA
    }
    .box:nth-child(8){
        background: #FAFAFA
    }
    .box:nth-child(10){
        background: #FAFAFA
    }
    .box:nth-child(12){
        background: #FAFAFA
    }

}
