
.home {
    padding-bottom: 90px;

    .banner {
        height: 930px;
        background: url('../images/banner.png') no-repeat top center;
      
        width: 100%
        background-size: cover
    }

    .icon-box {
        margin-top: 80px;

        li {
            margin-right: 213px;
        }

        li:last-child {
            margin-right: 0;
        }
    }

    .about-title {
        height: 147px;
        width: 100%;
        text-align: center;
        background: url('../images/title_bg2.png') no-repeat top center;
        background-size: auto 90px;

        .tit {
            padding-top: 45px;
        }
    }
}